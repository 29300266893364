<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-18 15:18:30
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-19 21:32:13
-->
<template>
  <div class="container-warp">
    <el-tabs v-model="learnSearchFrom.status"  @tab-click="_tabClick" :style="{'--font-Color' : fontColor}">
        <el-tab-pane label="进行中" name="1"></el-tab-pane>
        <el-tab-pane label="已完成" name="2"></el-tab-pane>
    </el-tabs>
    <div v-if="learnArrange.length===0" class="no-data-style">
      <img src="@/assets/img/img_null_data.png" alt=""/>
      <div>暂无数据~</div>
    </div>
    <div v-else>
      <div v-for="(item,index) in learnArrange" :key="index">
        <div class="study-item">
          <div class="study-item-left">
            <div class="img-box" @click="_goDetail(item)">
              <img :src="item.cover" alt=""/>
            </div>
            <div class="info-box">
              <div class="info-box-t">
                {{item.planName||'—'}}
                <span>{{item.typeName||'—'}}</span>
              </div>
              <div class="info-box-m">
                {{item.description?item.description:'—'}}
              </div>
              <div class="info-box-b">
                <div class="info-box-b-l">
                  <img src="@/assets/img/icon_Duration.png" alt="" />总时长
                  <span style="color: #0089FF;">{{_convertTimeToHMS(Number(item.totalTime||0)*1000)}}</span>
                </div>
                <div class="line-box"></div>
                <div class="info-box-b-m">
                  <img src="@/assets/img/icon_Course.png" alt="" />
                  <span style="color: #0089FF;" >{{item.courseSum||0}}</span>门课程
                </div>
                <div class="line-box"></div>
                <div class="info-box-b-r">
                  <img src="@/assets/img/icon_Finaldate.png" alt=""/>结束时间 {{item.endTime||'—'}}
                </div>
              </div>
            </div>
          </div>
          <div class="study-item-right" @click="_goDetail(item)">
          <!-- status: 0：未开始；1：进行中；2：已结束 -->
            <el-button type="primary" plain v-if="item.status=='1'" :style="{'--lock-btn': $store.state.settings.theme}">继续学习</el-button>
            <el-button type="primary" plain v-else-if="learnSearchFrom.status=='2'" :style="{'--lock-btn': $store.state.settings.theme}">开始学习</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudentLearnArrangeApi } from '@/api/study.js'
export default {
  data () {
    return {
      fontColor: this.$store.state.settings.theme,
      learnSearchFrom: {
        status: '1',
        page: 1,
        pageSize: 10
      },
      learnArrange: [] // 学习安排列表
    }
  },
  created () {
    this._getStudentLearnArrangeList()
  },
  mounted () {
    this.fontColor = this.$store.state.settings.theme
  },
  methods: {
    // 获取学生学习安排列表
    _getStudentLearnArrangeList () {
      getStudentLearnArrangeApi(this.learnSearchFrom).then((res) => {
        if (res.code === 200) {
          this.learnArrange = res.data
          console.log(res, 'hkjhjhgjh')
        }
      })
    },
    // 切换tab
    _tabClick () {
      this.learnSearchFrom.page = 1
      this.learnSearchFrom.pageSize = 10
      this._getStudentLearnArrangeList()
    },
    // 去详情
    _goDetail (item) {
      console.log(item, 'hjghkj00')
      // return
      this.$router.push({
        path: '/myStudyPlanDetail',
        query: {
          studyPlanId: item.id
        }
      })
    },
    // 将毫秒转成时分秒
    _convertTimeToHMS  (tempTime) {
      let formatTime = ''
      const hours = parseInt((tempTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = parseInt((tempTime % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = parseInt((tempTime % (1000 * 60)) / 1000)
      const tempHours = hours > 9 ? hours : '0' + hours
      const tempMin = minutes > 9 ? minutes : '0' + minutes
      const tempSec = seconds > 9 ? seconds : '0' + seconds
      if (tempHours != 0) {
        formatTime = (`${tempHours}时${tempMin}分${tempSec}秒`).slice(0, 9)
      } else if (tempMin != 0) {
        formatTime = (`${tempMin}分${tempSec}秒`).slice(0, 6)
      } else {
        formatTime = (`${tempSec}秒`).slice(0, 3)
      }
      return formatTime
    }
  }
}
</script>

<style lang="scss" scoped>
/* tabs样式 */
/deep/.el-tabs__item.is-active{
  color:var(--font-Color)!important;
}
/deep/.el-tabs__item{
  color: #737373!important;
}
.container-warp {
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  height: 84vh;
  overflow-y: scroll;
}
.study-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 16px;
  background: linear-gradient(to bottom, #f0f8ff, #ffffff);
  border: 1px solid #e7f4ff;
  border-radius: 8px;
  .study-item-left {
    display: flex;
    .img-box {
      margin-right: 5px;
      width: 120px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .info-box {
      .info-box-t {
        margin-left: 6px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          padding: 3px 12px;
          margin-left: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 8px 0px 8px 0px;
          background: linear-gradient(to right, #31adf6, #5096ff);
        }
      }
      .info-box-m {
        margin: 8px 0 16px 6px;
        font-size: 14px;
        color: #666666;
      }
      .info-box-b {
        display: flex;
        align-items: center;
        margin-left: 6px;
        font-size: 12px;
        color: #666666;
        .info-box-b-l,
        .info-box-b-m,
        .info-box-b-r {
          img {
            margin-right: 4px;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }
        .line-box {
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background: #dcdcdc;
        }
      }
    }
  }
}
// 没有数据样式
.no-data-style{
  text-align: center;
  img{
    width:30%;
  }
}
</style>
